<template>
  <div>
    <div class="box">
      <div class="header">
        <div class="logo mb-2">
          <nuxt-link title="Thames Valley Pigeon Shooting" to="/">Thames Valley Pigeon Shooting</nuxt-link>
        </div>
        <b-navbar id="navbar-main" toggleable="lg" type="dark">
          <b-navbar-brand class="d-lg-none">Menu</b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"/>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item
                :to="item.url"
                :key="item.name"
                :title="item.name"
                v-for="item in nav"
              >
                {{item.name}}
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
      </div>
    </div>
    <div class="box">
      <nuxt/>
    </div>
    <div class="footer">
      <div class="container">
        <div class="row">
          <div class="col">
            <span>&copy; ProStalk - Will Beasley - {{ new Date().getFullYear() }}</span>
          </div>
          <div class="col-md-auto">
            <ul class="inline">
              <li>
                <a
                  class="mx-1"
                  :href="item.url"
                  :title="item.name"
                  v-text="item.name"
                  v-for="item in nav"
                />
              </li>
            </ul>
          </div>
          <div class="col">
            <ul class="social">
              <li class="fb">
                <a
                  href="https://www.facebook.com/pages/Thames-Valley-Pigeon-Shooting/388414151288634"
                  title="Facebook"
                  target="_blank" rel="noopener">
                  Facebook
                </a></li>
              <li class="tw">
                <a href="https://twitter.com/ukpigeonguide"
                   title="Twitter"
                   target="_blank" rel="noopener">
                  Twitter
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        nav: [
          {name: "Home", url: '/'},
          {name: "Packages", url: '/packages'},
          {name: "Important Information", url: '/important-info'},
          {name: "About Will", url: '/about-will'},
          {name: "Gallery", url: '/gallery'},
          {name: "Contact", url: '/contact'},
          {name: "Deerstalking", url: '/deerstalking'},
        ]
      }
    }
  }
</script>

<style lang="scss">
@import "~assets/scss/default-theme";
@import "~assets/scss/style";
@import "~assets/scss/custom";

.social > li {
  list-style: none;
}
</style>
